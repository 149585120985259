import React, {Component} from 'react';
import Strapi from 'strapi-sdk-javascript/build/main';
import './App.css';
import Glide from '@glidejs/glide';
import {InView} from 'react-intersection-observer';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import Modal from 'react-modal';
import WebFont from 'webfontloader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Image, CloudinaryContext, Transformation} from 'cloudinary-react';

const strapi = new Strapi('https://studio-mi-service.herokuapp.com');

WebFont.load({
    custom: {
        families: ['Open Sans Hebrew'],
        urls: ['//fonts.googleapis.com/earlyaccess/opensanshebrew.css'
        ]
    },
    google: {
        families: ['Open Sans:300,400,700', 'sans-serif']
    }
});
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            hasMore: true,
            isLoading: false,
            lightboxIsOpen: false,
            currentImage: 0,
            items: [],
            posts: [],
            images: [],
            modalImages: [],
            modalContent: ''
        };
        this.loadPolyfills();
        window.onscroll = () => {
            const {
                state: {
                    error,
                    isLoading,
                    hasMore,
                },
            } = this;
            if (error || isLoading || !hasMore) return;
            if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 200) {
                this.loadWorks();
            }
        };
    }

    loadWorks() {
        this.setState({isLoading: true}, () => {
            strapi.getEntries('works', {_start: this.state.posts.length, _limit: 50, _sort: 'sort:DESC'})
                .then((results) => {
                    function translateFix(Glide, Components, Events) {
                        return {
                            modify(translate) {
                                return translate;
                            }
                        }
                    }

                    function initGlide(el, delay) {
                        new Glide(el, {type: 'carousel', startAt: 0, gap: 0}).mutate([translateFix]).mount();
                    }

                    function initModalGlide(el) {
                        let start = this.state.currentImage;
                        new Glide(el, {type: 'carousel', startAt: start, gap: 0}).mutate([translateFix]).mount();
                    }

                    this.setState({
                        items: [
                            ...this.state.items,
                            ...(results.map((item, key) => {
                                let className = 'grid-item';
                                className += item.imageLinks.length > 1 ? ' slider' : '';
                                if (item.shape === 'q' && item.size > 1) {
                                    className += ' span-quad-' + item.size;
                                }
                                let clickHandler = (key, i) => {
                                    this.setState({
                                        lightboxIsOpen: true,
                                        modalContent: this.state.modalImages[key] || '',
                                        currentImage: i
                                    });
                                };
                                /*
                                let images = item.imageLinks.map((image, k) => {
                                    let imgSrc = image.replace('/upload', '/upload/q_80,w_800,h_800,c_fill');
                                    return (
                                        <img key={'img-glide-slider-' + key + '-' + k}
                                             data-place={this.state.images.length + k} draggable='false'
                                             className='js-lazy-image glide__slide'
                                             onClick={clickHandler.bind(this, parseInt(this.state.posts.length + key), k)}
                                             src={imgSrc}
                                             alt={item.name}/>
                                    );
                                });
                                */
                                let images = item.imageLinks.map((image, k) => {
                                    let publicID = (image.substr(image.indexOf('/upload') + 8)).replace('.png','');
                                    return (
                                        <Image publicId={publicID}  className='js-lazy-image glide__slide'
                                               alt={item.name} data-place={this.state.images.length + k}
                                               draggable='false' key={'img-glide-slider-' + key + '-' + k}
                                               onClick={clickHandler.bind(this, parseInt(this.state.posts.length + key), k)}>
                                            <Transformation width="800" height="800" crop="fill" quality={80} fetchFormat="auto"/>
                                        </Image>
                                    );
                                });
                                this.setState({
                                    images: [
                                        ...this.state.images,
                                        ...item.imageLinks
                                    ]
                                });
                                return (
                                    <InView key={'glide-slider-' + key} as="div" className={className}
                                            triggerOnce={true} threshold={0.01}
                                            onChange={(inView, entry) => inView === true ? initGlide(entry.target, key) : false}>
                                        <div className='glide__track' data-glide-el='track'>
                                            <div className='glide__slides'>
                                                {images}
                                            </div>
                                        </div>
                                    </InView>
                                );
                            }))
                        ],
                        modalImages: [
                            ...this.state.modalImages,
                            ...(results.map((item, key) => {
                                return (
                                    <InView key={'modal-slider-' + key} as="div"
                                            triggerOnce={true} threshold={0.01}
                                            onChange={(inView, entry) => inView === true ? initModalGlide.bind(this,entry.target)() : false}>
                                        <div className='glide__track' data-glide-el='track'>
                                            <div className='glide__slides'>
                                                {item.imageLinks.map((image, k) => {
                                                    let publicID = (image.substr(image.indexOf('/upload') + 8)).replace('.png','');
                                                    return (
                                                        <div className='glide__slide'>
                                                            <Image publicId={publicID} className='js-lazy-image'
                                                                   alt={item.name} draggable='false'
                                                                   key={'modal-glide-slider-' + key + '-' + k}>
                                                                <Transformation width="1200" crop="scale" quality={80} fetchFormat="auto"/>
                                                            </Image>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="glide__arrows" data-glide-el="controls">
                                            <button className="glide__arrow glide__arrow--left"
                                                    data-glide-dir="<">
                                                <picture>
                                                    <source src="/media/left.svg" type="image/svg+xml"/>
                                                    <img src="/media/left.png"/>
                                                </picture>
                                            </button>
                                            <button className="glide__arrow glide__arrow--right"
                                                    data-glide-dir=">">
                                                <picture>
                                                    <source src="/media/right.svg" type="image/svg+xml"/>
                                                    <img src="/media/right.png"/>
                                                </picture>
                                            </button>
                                        </div>
                                    </InView>
                                );
                            }))
                        ]
                    });
                    this.countWorks();
                    this.setState({
                        isLoading: false,
                        posts: [
                            ...this.state.posts,
                            ...results
                        ]
                    });
                })
                .catch((err) => {
                    this.setState({
                        error: err.message,
                        isLoading: false,
                    });
                })
        });
    };

    async countWorks() {
        const len = await strapi.getEntryCount('works');
        if (len) {
            this.setState({
                hasMore: (this.state.posts.length < len)
            });
        }
    }

    async componentDidMount() {
        try {
            this.loadWorks();
        }
        catch (err) {
            alert(err);
        }
    }

    async loadPolyfills() {
        if (typeof window.IntersectionObserver === 'undefined') {
            await import('intersection-observer')
        }
    }

    handleInputChange(ev) {
        if (ev.target.value) {
            ev.target.classList.add('not-empty');
        } else {
            ev.target.classList.remove('not-empty');
        }
    }

    openContactForm() {
        this.setState({
            lightboxIsOpen: true,
            modalContent: (<section className="get-in-touch">
                <div className="title">Get in <strong>touch</strong></div>
                <form className="contact-form row" onSubmit={this.sendEmail.bind(this)}>
                    <div className="form-field col x-50">
                        <input id="name" name='subject' className="input-text js-input" type="text" onChange={this.handleInputChange.bind(this)} required/>
                            <label className="label" htmlFor="name">Name</label>
                    </div>
                    <div className="form-field col x-50">
                        <input id="email" name='replyTo' className="input-text js-input" type="email" onChange={this.handleInputChange.bind(this)} required/>
                            <label className="label" htmlFor="email">E-mail</label>
                    </div>
                    <div className="form-field col x-100">
                        <textarea id="message" name='text' rows="2" className="input-text js-input" onChange={this.handleInputChange.bind(this)} required/>
                            <label className="label" htmlFor="message">Message</label>
                    </div>
                    <div className="form-field col x-100 align-center">
                        <input className="submit-btn" type="submit" value="Submit"/>
                    </div>
                </form>
            </section>)
        });
    };

    openLightbox(event, obj) {
        this.setState({
            currentImage: parseInt(event.target.getAttribute('data-place')),
            lightboxIsOpen: true,
        }, () => {
            let grid = document.getElementsByClassName('grid-container')[0];
            grid.classList.add('blur');
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1
        });
    }

    closeLightbox() {
        this.setState({lightboxIsOpen: false})
    }

    sendEmail(ev) {
        ev.preventDefault();
        strapi.axios.post('/email',new FormData(ev.target)).then(() => {
            this.setState({
                modalContent: <section className="get-in-touch">
                    <div className="title"><strong>Thank you!</strong><p></p>We'll be in touch with you soon</div>
                </section>
            })
        }).catch(() => {
            toast.error("Oops! your message could not be sent, check your details or try again later.", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: false
            });
        });
    }

    render() {
        return (
            <main>
                <CloudinaryContext cloudName="studiomi">
                    <div className='pos-top'>
                        <div className="logo-container">
                            <picture>
                                <source src="/media/logo.svg" type="image/svg+xml"/>
                                <img src="/media/logo.png"/>
                            </picture>
                        </div>
                        <div className="icons">
                            <a className="icon" target="_blank" rel="noopener noreferrer"
                               href="https://www.facebook.com/MiStudio/">
                                <picture>
                                    <source src="/media/fb.svg" type="image/svg+xml"/>
                                    <img src="/media/fb.png"/>
                                </picture>
                            </a>
                            <a className="icon" target="_blank" rel="noopener noreferrer"
                               href="https://www.instagram.com/mi.studiomi/">
                                <picture>
                                    <source src="/media/instagram.svg" type="image/svg+xml"/>
                                    <img src="/media/instagram.png"/>
                                </picture>
                            </a>
                            <a className="icon" rel="noopener noreferrer"
                               onClick={this.openContactForm.bind(this)} href={'#contact'}>
                                <picture>
                                    <source src="/media/mail.svg" type="image/svg+xml"/>
                                    <img src="/media/mail.png"/>
                                </picture>
                            </a>
                        </div>
                    </div>
                    <div className='pos-bottom'>
                        <a className="icon" target="_blank" rel="noopener noreferrer"
                           href="https://www.facebook.com/MiStudio/">
                            <picture>
                                <source src="/media/fb.svg" type="image/svg+xml"/>
                                <img src="/media/fb.png"/>
                            </picture>
                        </a>
                        <a className="icon" target="_blank" rel="noopener noreferrer"
                           href="https://www.instagram.com/mi.studiomi/">
                            <picture>
                                <source src="/media/instagram.svg" type="image/svg+xml"/>
                                <img src="/media/instagram.png"/>
                            </picture>
                        </a>
                        <a className="icon" rel="noopener noreferrer"
                           onClick={this.openContactForm.bind(this)} href={'#contact'}>
                            <picture>
                                <source src="/media/mail.svg" type="image/svg+xml"/>
                                <img src="/media/mail.png"/>
                            </picture>
                        </a>
                    </div>
                    <div className='grid-container'>{this.state.items}</div>
                    <Modal isOpen={this.state.lightboxIsOpen}
                           onRequestClose={this.closeLightbox.bind(this)}
                           style={{
                               overlay: {
                                   position: 'fixed',
                                   top: 0,
                                   left: 0,
                                   right: 0,
                                   bottom: 0,
                                   backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                   zIndex: 20
                               },
                               content: {
                                   position: 'relative',
                                   top: '50%',
                                   left: '0',
                                   right: '0',
                                   bottom: 'auto',
                                   border: 'none',
                                   background: 'none',
                                   overflow: 'hidden',
                                   WebkitOverflowScrolling: 'touch',
                                   borderRadius: 'none',
                                   outline: 'none',
                                   padding: '20px',
                                   transform: 'translateY(-50%)'
                               }
                           }}
                    >
                        {this.state.modalContent}
                    </Modal>
                    <ToastContainer />
                    <span className={'close rounded'} onClick={this.closeLightbox.bind(this)}/>
                </CloudinaryContext>
            </main>
        )
    }
}

export default App;
